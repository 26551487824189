<template>
  <div class="person" @click="click">
    <a v-if="cornerIcon && person.phone" :href="`tel:${person.phone}`">
      <VueIcon
        v-if="cornerIcon"
        :width="cornerIcon.width"
        :height="cornerIcon.height"
        :iconName="cornerIcon.name"
        iconColor="#6dd400"
      ></VueIcon
    ></a>
    <VueText sizeLevel="2" color="grey-30" class="person-title">{{ person.title }}</VueText>
    <VueText weightLevel="3" sizeLevel="4" color="grey-40">{{ person.name }}</VueText>
    <VueText v-if="person.phone" weightLevel="1" sizeLevel="4" color="grey-40">
      {{ person.phone }}
    </VueText>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';

export default {
  name: 'BrandMeetPerson',
  components: {
    VueText,
    VueIcon,
  },
  props: {
    cornerIcon: {
      type: Object,
    },
    person: {
      type: Object,
    },
  },
  methods: {
    click(e) {
      this.$emit('click', e);
    },
  },
};
</script>
<style scoped lang="scss">
.person {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  background-color: palette-color-level('white', '100');
  padding: palette-space-level('15');
  border: 1px solid palette-color-level('grey', '20');
  min-width: 0;
  flex-wrap: wrap;
  border-radius: palette-radius-level('1');

  &-title {
    max-width: calc(100% - 10px);
    margin-bottom: palette-space-level('5');
  }

  svg {
    position: absolute;
    right: palette-space-level('5');
    top: 50%;
    transform: translateY(-50%);
    width: 38px;
    height: 38px;
  }
}
</style>
